<template>
  <section>
    <b-card>
      <b-card-header style="padding-top: 0px; padding-right: 0px">
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4>
              <b style="color: cornflowerblue">Daftar Peserta </b>
            </h4>
          </b-card-title>
        </div>
        <!-- <b-nav :align="selected">
          <b-nav-item>
            <router-link to="/admin/add_student">
              <b-button variant="success">
                <feather-icon icon="PlusSquareIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Tambah</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav> -->
      </b-card-header>

      <b-col cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="btn-icon text-center"
            >
              <b-nav>
                <b-nav-item>
                  <b-button
                    variant="gradient-success"
                    class="btn-icon"
                    @click="
                      $router.push({
                        name: 'edit_student',
                        params: { id: props.row.id },
                      })
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-nav-item>
                <b-nav-item>
                  <b-button
                    variant="gradient-danger"
                    class="btn-icon"
                    @click="deleteData(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-nav-item>
              </b-nav>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  :align="pagnation_posisition"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-card>
  </section>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BCardHeader,
  BTooltip,
  BCardTitle,
  BNav,
  BNavItem,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table/src";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    VueGoodTable,
    BCard,
    ToastificationContent,
    BAvatar,
    Swal,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCardHeader,
    BNav,
    BNavItem,
    BTooltip,
    BCardTitle,
    BCol,
  },
  data() {
    return {
      selected: "right",
      pagnation_posisition: "right",
      pageLength: 10,
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        // },
        {
          label: "Nama",
          field: "nama",
        },
        {
          label: "Kampus",
          field: "kampus",
        },
        {
          label: "Jurusan",
          field: "jurusan",
        },
        {
          label: "Prodi",
          field: "jenjang",
        },
        {
          label: "Telepon",
          field: "telepon",
        },
        {
          label: "Level",
          field: "level",
        },
        {
          label: "Aksi",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "users/view")
        .then((res) => {
          this.rows = res.data.data;
        });
    },
    // async deleteData(id) {
    //   await Swal.fire({
    //     title: "Ingin Hapus Data?",
    //     text: "Data akan dihapus?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Ya, sudah selesai!",
    //   })
    //     .then((result) => {
    //       if (result.value) {
    //         this.$http
    //           .delete(
    //             process.env.VUE_APP_BACKEND_URL + `categories/delete/${id}`
    //           )
    //           .then((result) => {
    //             this.getData();
    //             this.$toast(
    //               {
    //                 component: ToastificationContent,
    //                 props: {
    //                   title: "Notification",
    //                   icon: "CheckCircleIcon",
    //                   text: "Hapus Data",
    //                   variant: "danger",
    //                 },
    //               },
    //               {
    //                 timeout: 5000,
    //               },
    //               {
    //                 position: "top-right",
    //               }
    //             );
    //           });
    //       } else {
    //         this.$toast(
    //           {
    //             component: ToastificationContent,
    //             props: {
    //               title: "Notification",
    //               icon: "CheckCircleIcon",
    //               text: "Batal Hapus Data",
    //               variant: "warning",
    //             },
    //           },
    //           {
    //             timeout: 5000,
    //           },
    //           {
    //             position: "top-right",
    //           }
    //         );
    //       }
    //     })
    //     .then((result) => {})
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
